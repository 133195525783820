import { useEffect, useRef } from "react";
import projectData from "../../data/mini-project.json";
import "./Projects.scss";
import { create } from "@lottiefiles/lottie-interactivity";

import {
  projectCardRise,
  imageScale,
  exitTransition,
} from "../../utils/animations";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Projects = () => {
  const lottiee = useRef(null);

  useEffect(() => {
    import("@lottiefiles/lottie-player");
  });

  useEffect(() => {
    if (lottiee.current !== null) {
      lottiee.current.addEventListener("load", function (e) {
        create({
          player: "#lottie-player-0",
          container: "#project-container-0",
          mode: "cursor",
          actions: [
            {
              type: "hold",
            },
          ],
        });
        create({
          player: "#lottie-player-1",
          container: "#project-container-1",
          mode: "cursor",
          actions: [
            {
              type: "hold",
            },
          ],
        });
        create({
          player: "#lottie-player-2",
          container: "#project-container-2",
          mode: "cursor",
          actions: [
            {
              type: "hold",
            },
          ],
        });
        create({
          player: "#lottie-player-3",
          container: "#project-container-3",
          mode: "cursor",
          actions: [
            {
              type: "hold",
            },
          ],
        });
      });
    }
  }, [lottiee]);
  return (
    <motion.main
      className="projects"
      id="allprojects"
      exit={{ y: "100vh", opacity: 0 }}
      transition={exitTransition()}
    >
      {projectData?.map((project) => (
        <Link
          to={`/work/${project.projectTitle.toLowerCase()}`}
          key={project.projectTitle}
          className="project__link-container"
        >
          <motion.div
            className="project__container"
            id={`project-container-${projectData.indexOf(project)}`}
            style={{ background: project.style }}
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true, amount: 0.17 }}
            variants={projectCardRise}
            whileHover="hover"
          >
            <motion.div
              className="project__img-container"
              variants={imageScale}
            >
              <img
                className="project__img"
                src={project.src}
                alt="project screenshot"
              ></img>
            </motion.div>

            <div className="project__icon-container">
              <h2 className="project__name">{project.projectTitle} </h2>
              <div className="project__eye">
                {" "}
                <lottie-player
                  ref={lottiee}
                  speed={1.5}
                  id={`lottie-player-${projectData.indexOf(project)}`}
                  src="https://assets2.lottiefiles.com/private_files/lf30_jk4hpapf.json"
                  style={{ width: "1em", height: "1em", display: "block" }}
                ></lottie-player>
              </div>
            </div>
          </motion.div>
        </Link>
      ))}
    </motion.main>
  );
};

export default Projects;
