import { HashLink as Link } from "react-router-hash-link";
import "./Contact.scss";
import {
  letterAnimation,
  staggerContainer,
  exitTransition,
} from "../../utils/animations";
import { motion } from "framer-motion";

const Contact = () => {
  return (
    <motion.div
      id="allcontact"
      className="contact"
      initial={"hidden"}
      whileInView={"show"}
      exit={{ y: "100vh", opacity: 0 }}
      transition={exitTransition()}
      viewport={{ once: false, amount: 0.3 }}
      variants={staggerContainer}
    >
      <div className="contact__text-container">
        <motion.h2 className="contact__title" variants={letterAnimation}>
          <span className="contact__title-span"> X</span> Custom Works
        </motion.h2>
      </div>
      <div className="contact__text-container">
        <motion.h2 className="contact__title" variants={letterAnimation}>
          <span className="contact__title-span"> X</span> New Projects?
        </motion.h2>
      </div>
      <div className="contact__text-container">
        <motion.p className="contact__paragraph" variants={letterAnimation}>
          If you need custom work, want to collaborate with me or propose some
          new project – drop a line:
        </motion.p>
      </div>
      <div className="contact__text-container">
        <motion.div className="contact__links" variants={letterAnimation}>
          <Link to={"mailto:ragib@hotmail.ca"} className="contact__link">
            ragib@hotmail.ca
          </Link>
          <Link
            to={"https://linkedin.com/in/ragibsina"}
            target="_blank"
            className="contact__link"
          >
            linkedin
          </Link>
          <Link
            to={"https://github.com/ragibs"}
            target="_blank"
            className="contact__link"
          >
            github
          </Link>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Contact;
