import "./Nav.scss";
import { HashLink as Link } from "react-router-hash-link";
import { motion, useScroll, useTransform } from "framer-motion";
import {
  letterAnimation,
  staggerContainer,
  lineAnimation,
  exitTransition,
} from "../../utils/animations";

const Nav = () => {
  const { scrollYProgress } = useScroll();
  const workIn = useTransform(
    scrollYProgress,
    [0.2, 0.7, 1],
    ["1.1em", "2em", "1.1em"]
  );
  const contactIn = useTransform(scrollYProgress, [0.7, 1], ["1.1em", "2em"]);

  return (
    <motion.nav
      className="nav"
      initial="hidden"
      animate="show"
      exit={{ y: "100vh", opacity: 0 }}
      transition={exitTransition()}
      variants={staggerContainer}
    >
      <Link className="nav__logo" to="#hero">
        <motion.h1 className="nav__title" variants={letterAnimation}>
          Ragib <span className="nav__title-span">Sina</span>
        </motion.h1>
      </Link>
      <div className="nav__spacer"></div>
      <Link to="#allprojects" className="nav__link">
        <motion.div
          className="nav__link-text"
          variants={letterAnimation}
          style={{ fontSize: workIn }}
        >
          Work.
        </motion.div>
      </Link>
      <Link to="#allcontact" className="nav__link">
        <motion.div
          className="nav__link-text"
          variants={letterAnimation}
          style={{ fontSize: contactIn }}
        >
          Contact.
        </motion.div>
      </Link>
      <motion.div
        className="nav__spacer2"
        variants={lineAnimation}
      ></motion.div>
    </motion.nav>
  );
};

export default Nav;
