export const textVariant = (delay) => ({
  hidden: {
    y: 50,
  },
  show: {
    y: 0,
    transition: {
      type: "spring",
      duration: 0.7,
      stiffness: 70,
      delay, //staggerchildren doesn't work with delay!
    },
  },
});

export const projectCardRise = {
  offscreen: { y: 300, opacity: 0 },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 1,
    },
  },
};

export const imageScale = {
  initial: { scale: 1 },
  hover: {
    scale: 1.04,

    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      duration: 0.6,
    },
  },
};

export const fadeIn = (direction, type, delay, duration) => ({
  hidden: {
    x: direction === "left" ? 100 : direction === "right" ? -100 : 0,
    y: direction === "up" ? 100 : direction === "down" ? -100 : 0,
    opacity: 0,
  },
  show: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      type,
      delay,
      duration,
      ease: "easeOut",
    },
  },
});

export const staggerContainer = {
  hidden: {},
  show: {
    transition: {
      delayChildren: 0,
      staggerChildren: 0.3,
    },
  },
};

export const letterAnimation = {
  hidden: { y: "100vh" },
  show: {
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1,
    },
  },
};

export const lineAnimation = {
  hidden: { x: "-100vw" },
  show: {
    x: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1,
      delay: 1.5,
    },
  },
};

export const exitTransition = (duration = 1) => ({
  duration: duration,
  ease: [0.43, 0.13, 0.23, 0.96],
});

//project page animations

export const projectCardAppear = {
  offscreen: { opacity: 0, scale: 0.7 },
  onscreen: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: [0.6, 0.01, 0.05, 0.95],
      duration: 1,
      delay: 0.2,
    },
  },
};

export const projectImageScale = {
  hidden: { scale: 0.5, y: "200vh", opacity: 0 },
  show: {
    scale: 1,
    y: 0,
    opacity: 1,

    transition: {
      ease: [0.43, 0.13, 0.23, 0.96],
      duration: 1,
      delay: 0.5,
    },
  },
};

export const projectSubtitleAnimation = {
  hidden: { y: 400 },
  show: {
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 0.3,
    },
  },
};

export const projectDelay = {
  show: {
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.1,
    },
  },
};
