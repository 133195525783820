import "./Hero.scss";
import { motion } from "framer-motion";
import {
  staggerContainer,
  letterAnimation,
  exitTransition,
} from "../../utils/animations";

const Hero = () => {
  return (
    <motion.div
      className="hero"
      initial="hidden"
      whileInView="show"
      exit={{ y: "100vh", opacity: 0 }}
      transition={exitTransition()}
      id="hero"
      variants={staggerContainer}
      viewport={{ once: false, amount: 0.25 }}
    >
      <div className="hero__title-container">
        <motion.h2 className="hero__title" variants={letterAnimation}>
          Software
          <span className="hero__title-span"> Developer</span>.
        </motion.h2>
      </div>
      <div className="hero__title-container">
        <motion.h2 className="hero__title" variants={letterAnimation}>
          Love Everything <span className="hero__title-span"> JS</span>.
        </motion.h2>
      </div>
      <div className="hero__title-container">
        <motion.h2 className="hero__title" variants={letterAnimation}>
          Based in <span className="hero__title-span"> Calgary, AB</span>.
        </motion.h2>
      </div>
    </motion.div>
  );
};

export default Hero;
