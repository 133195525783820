import "./Homepage.scss";
import Hero from "../../components/Hero/Hero";
import Nav from "../../components/Nav/Nav";
import Contact from "../../components/Contact/Contact";
import Projects from "../../components/Projects/Projects";

const Homepage = () => {
  return (
    <div className="main">
      <Nav />
      <div className="contents">
        <Hero />
        <Projects />
        <Contact />
      </div>
    </div>
  );
};

export default Homepage;
