import React from "react";
import useDocumentTitle from "../../utils/useDocumentTitle";
import "./Work.scss";
import ScrollToTop from "../../utils/ScrollToTop";
import { useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import {
  letterAnimation,
  projectCardAppear,
  projectImageScale,
  projectSubtitleAnimation,
  projectDelay,
  exitTransition,
} from "../../utils/animations";
import { Link, useParams } from "react-router-dom";
import projectDetails from "../../data/projectData.json";
const Work = () => {
  useEffect(() => {
    import("@lottiefiles/lottie-player");
  });

  const { scrollYProgress } = useScroll();
  const scaleTitle = useTransform(scrollYProgress, [0, 0.3], [1, 8]);
  const scaleImg = useTransform(scrollYProgress, [0.5, 0.7], [0.8, 1]);

  //first parameter is [0,1] means start to bottom of the scroll
  //second parameter is scaling from 1 to 10 as we scroll

  const { id } = useParams();
  // convert params to first character in caps
  const projectFormated = id.charAt(0).toUpperCase() + id.slice(1);
  //find function from json
  const project = projectDetails.find(
    (project) => project.projectTitle === projectFormated
  );

  useDocumentTitle(`/ragib/${id}`); // change page title based on project

  return (
    <ScrollToTop>
      <motion.main
        className="work"
        initial="hidden"
        animate="show"
        exit="exit"
        variants={projectDelay}
      >
        {/* trying out an overlay div over the screen to smooth out page transition need to pass  */}
        <motion.div
          className="work__overlay"
          initial={{ y: 0 }}
          animate={{ y: "-100vh" }}
          transition={exitTransition()}
          style={{ background: project?.backgroundColor }}
        ></motion.div>
        <header className="work__header">
          <div
            className="work__title-container"
            style={{
              background: project?.backgroundGradient,
            }}
          >
            <motion.h2
              variants={letterAnimation}
              className="work__title"
              style={{
                scale: scaleTitle,
              }}
            >
              {project?.projectTitle}
            </motion.h2>
          </div>
        </header>
        <motion.div
          className="work__image-container"
          variants={projectImageScale}
        >
          <img
            className="work__image"
            src={project?.mainImage}
            alt="project screenshot"
          />
        </motion.div>
        <div className="work__info">
          <div className="work__outcome">
            {/* h2 header animation */}
            <motion.span
              className="row-title"
              initial={"hidden"}
              whileInView={"show"}
              variants={projectDelay}
              viewport={{ once: true, amount: 0.2 }}
            >
              {[..."Overview."].map((letter, index) => (
                <motion.span
                  className="work__outcome-title-tablet"
                  variants={projectSubtitleAnimation}
                  key={index}
                >
                  {letter}
                </motion.span>
              ))}
            </motion.span>
            <div className="work__outcome-text-container">
              <motion.h1
                className="work__outcome-title"
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.2 }}
                variants={projectCardAppear}
              >
                Overview.
              </motion.h1>
              <motion.p
                className="work__outcome-text"
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.2 }}
                variants={projectCardAppear}
              >
                {project?.about}
              </motion.p>
            </div>
          </div>
          <div className="work__build">
            {/* h2 header animation */}
            <motion.span
              className="row-title"
              initial={"hidden"}
              whileInView={"show"}
              variants={projectDelay}
              viewport={{ once: true, amount: 0.2 }}
            >
              {[..."Build."].map((letter, index) => (
                <motion.span
                  className="work__build-title-tablet"
                  variants={projectSubtitleAnimation}
                  key={index}
                >
                  {letter}
                </motion.span>
              ))}
            </motion.span>
            <div className="work__build-text-container">
              <motion.h1
                className="work__build-title"
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.2 }}
                variants={projectCardAppear}
              >
                Build.
              </motion.h1>
              {project?.build.map((build, index) => (
                <motion.p
                  className="work__build-text"
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={{ once: true, amount: 0.2 }}
                  variants={projectCardAppear}
                  key={index}
                >
                  {build}
                </motion.p>
              ))}

              <motion.div
                className="work__build-icons"
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.2 }}
                variants={projectCardAppear}
              >
                {project?.icons.map((icon, index) => (
                  <img
                    className="work__build-icon"
                    src={icon}
                    key={index}
                    alt="project icons"
                  />
                ))}
              </motion.div>
            </div>
          </div>
          <div className="work__features">
            {/* h2 header animation */}
            <motion.span
              className="row-title"
              initial={"hidden"}
              whileInView={"show"}
              variants={projectDelay}
              viewport={{ once: true, amount: 0.2 }}
            >
              {[..."Features."].map((letter, index) => (
                <motion.span
                  className="work__features-title-tablet"
                  variants={projectSubtitleAnimation}
                  key={index}
                >
                  {letter}
                </motion.span>
              ))}
            </motion.span>
            <div className="work__features-text-container">
              <motion.h1
                className="work__features-title"
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.2 }}
                variants={projectCardAppear}
              >
                Features.
              </motion.h1>
              {project?.features.map((feature, index) => (
                <motion.p
                  className="work__features-text"
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={{ once: true, amount: 0.2 }}
                  variants={projectCardAppear}
                  key={index}
                >
                  {feature}
                </motion.p>
              ))}
              <motion.div
                className="work__features-image-container"
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.2 }}
                variants={projectCardAppear}
                style={{ background: project?.backgroundColor }}
              >
                <motion.img
                  style={{ scale: scaleImg }}
                  className="work__features-image"
                  src={project?.projectImageTablet}
                  alt="project screenshot tablet"
                />
                <motion.img
                  style={{ scale: scaleImg }}
                  className="work__features-image-mobile"
                  src={project?.projectImageMobile}
                  alt="project screenshot mobile"
                />
              </motion.div>
            </div>
          </div>
          <div className="work__outcome">
            {/* h2 header animation */}
            <motion.span
              className="row-title"
              initial={"hidden"}
              whileInView={"show"}
              variants={projectDelay}
              viewport={{ once: true, amount: 0.2 }}
            >
              {[..."Challenges."].map((letter, index) => (
                <motion.span
                  className="work__outcome-title-tablet"
                  variants={projectSubtitleAnimation}
                  key={index}
                >
                  {letter}
                </motion.span>
              ))}
            </motion.span>
            <div className="work__outcome-text-container">
              <motion.h1
                className="work__outcome-title"
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.2 }}
                variants={projectCardAppear}
              >
                Challenges.
              </motion.h1>
              {project?.challenges.map((challenge, index) => (
                <motion.p
                  className="work__outcome-text"
                  initial={"offscreen"}
                  whileInView={"onscreen"}
                  viewport={{ once: true, amount: 0.2 }}
                  variants={projectCardAppear}
                  key={index}
                >
                  {challenge}
                </motion.p>
              ))}
            </div>
          </div>
          <div className="work__outcome">
            {/* h2 header animation */}
            <motion.span
              className="row-title"
              initial={"hidden"}
              whileInView={"show"}
              variants={projectDelay}
              viewport={{ once: true, amount: 0.2 }}
            >
              {[..."Preview."].map((letter, index) => (
                <motion.span
                  className="work__outcome-title-tablet"
                  variants={projectSubtitleAnimation}
                  key={index}
                >
                  {letter}
                </motion.span>
              ))}
            </motion.span>
            <div className="work__outcome-text-container">
              <motion.h1
                className="work__outcome-title"
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.2 }}
                variants={projectCardAppear}
              >
                Preview.
              </motion.h1>
              <motion.div
                className="work__demo-container"
                initial={"offscreen"}
                whileInView={"onscreen"}
                viewport={{ once: true, amount: 0.2 }}
                variants={projectCardAppear}
              >
                <Link
                  target="_blank"
                  className="work__demo-link"
                  to={project?.gitHub}
                >
                  <lottie-player
                    speed={0.7}
                    loop
                    autoplay
                    mode="bounce"
                    src="https://assets3.lottiefiles.com/packages/lf20_10alhrsx.json"
                    style={{
                      width: "3em",
                      height: "3em",
                      display: "inline-block",
                    }}
                  ></lottie-player>
                </Link>
                <Link
                  target="_blank"
                  className="work__demo-link"
                  to={project?.demo}
                >
                  <lottie-player
                    speed={0.7}
                    loop
                    autoplay
                    mode="bounce"
                    src="https://assets2.lottiefiles.com/private_files/lf30_jk4hpapf.json"
                    style={{
                      width: "3em",
                      height: "3em",
                      display: "inline-block",
                    }}
                  ></lottie-player>
                </Link>
              </motion.div>
            </div>
          </div>
        </div>
      </motion.main>
    </ScrollToTop>
  );
};

export default Work;
